// This page is for see all apartment in this floor
// You can change floor number in order to see other floors info
// When clicks to the apartment it shows apartment infos

import React, { useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import SVG from "./SVG";
import styles from "./index.module.scss";
import green from "../../assets/green.png";
import yellow from "../../assets/yellow.png";
import blue from "../../assets/blue.png";
import red from "../../assets/red.png";
import clsx from "clsx";
import { Button, Spin } from "antd";
import SVG3 from "./SVG3";
import ApartmentModel from "./ApartmentInfoModel";
import { useQuery } from "react-query";
import jwtAxios from "../../auth/jwt-api";

export default function Floor() {
  const [searchParams, setSearchParams] = useSearchParams();
  const floor = searchParams.get("floorNumber");
  // const apartment = searchParams.get("apartmentNumber");
  // const isEdit = searchParams.get("isEdit");

  const {
    data: apartments,
    isLoading,
    refetch: refetchFloor,
  } = useQuery(
    ["apartments", floor],
    () =>
      jwtAxios.get(
        `apartment?floor=${floor}&limit=50&attributes=id,status,block,numberInBlock`
      ),
    {
      onSuccess(apartments) {
        const results = {};
        apartments?.data?.data?.results?.rows?.map((apartment) => {
          results[`${apartment.block}${apartment.numberInBlock}`] = apartment;
        });
        apartments.data = results;
      },
    }
  );

  const {
    data: stats,
    isLoading: isStatusLoading,
    refetch: refetchStats,
  } = useQuery(["floor", floor], () =>
    jwtAxios.get(`apartment/floor-stats/${floor}`)
  );

  const onApartmentClick = (apartment) => () => {
    setSearchParams({ floorNumber: floor, apartmentNumber: apartment.id });
  };

  if (isNaN(floor) || !floor) {
    return <Navigate to="/floors" />;
  }
  return (
    <div className={styles.container}>
      <ApartmentModel refetchFloor={refetchFloor} refetchStats={refetchStats} />
      <div className={styles["floor-container"]}>
        <p>Floors</p>
        <div className={styles.floors}>
          {Array(22)
            .fill(1)
            .map((_, i) => {
              const currentFloor = 3 + i;
              return (
                <Button
                  key={currentFloor}
                  type={currentFloor == floor ? "primary" : ""}
                  onClick={() => setSearchParams({ floorNumber: currentFloor })}
                >
                  {currentFloor}
                </Button>
              );
            })}
        </div>
      </div>{" "}
      <div className={styles["svg-container"]}>
        {isLoading ? (
          <Spin
            spinning
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              aspectRatio: "1440 / 923",
            }}
          ></Spin>
        ) : (
          <div className={styles["svg-container"]}>
            <div className={styles["apartment-info"]}>
              <h1>Floor {floor}</h1>
            </div>
            {floor != 3 ? (
              <SVG
                onApartmentClick={onApartmentClick}
                apartments={apartments?.data}
              />
            ) : (
              <SVG3
                onApartmentClick={onApartmentClick}
                apartments={apartments?.data}
              />
            )}
          </div>
        )}
      </div>
      <div className={styles["info-container"]}>
        <h1>Apartment statuses</h1>
        {isStatusLoading ? (
          <Spin
            spinning
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          ></Spin>
        ) : (
          <div className={styles.statuses}>
            {stats?.data?.data?.results?.map((stat) => (
              <div className={clsx(styles[stat.status], styles.status)}>
                <img
                  src={
                    {
                      reserved: blue,
                      sold: red,
                      not_full_paid: yellow,
                      aviable: green,
                    }[stat.status]
                  }
                  alt=""
                />
                <h2>
                  {
                    {
                      reserved: "Reserved",
                      sold: "Sold",
                      not_full_paid: "Not fully paid",
                      aviable: "Aviable",
                    }[stat.status]
                  }{" "}
                  ({stat.total})
                </h2>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
