import React from "react";
import image from "../../assets/floor13.png";
import styles from "./index.module.scss";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

export default function SVG({ onApartmentClick, apartments }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const apartmentNumber = searchParams.get("apartmentNumber");

  return (
    <svg
      viewBox="0 0 1440 923"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={styles.svg}
    >
      <g style={{ mixBlendMode: "luminosity" }}>
        <rect width="1440" height="923" fill="url(#pattern0_34_4)" />
      </g>
      <path
        d="M97 132C97 102.177 121.177 78 151 78H200V201H97V132Z"
        className={clsx(
          styles[apartments.V3.status],
          styles.apartment,
          apartmentNumber == apartments.V3.id && styles.active
        )}
        onClick={onApartmentClick(apartments.V3)}
      />
      <path
        d="M293.088 88.5H323.11V77.9028H353.131V165.667H293.088V88.5Z"
        className={clsx(
          styles[apartments.V5.status],
          styles.apartment,
          apartmentNumber == apartments.V5.id && styles.active
        )}
        onClick={onApartmentClick(apartments.V5)}
      />
      <path
        d="M108.947 227.5V201H204V236H167.5V257H97V227.5H108.947Z"
        className={clsx(
          styles[apartments.V2.status],
          styles.apartment,
          apartmentNumber == apartments.V2.id && styles.active
        )}
        onClick={onApartmentClick(apartments.V2)}
      />
      <path
        d="M353 78H382V89.5H414V257H381.5V269.5L353.5 268.5V257H345.5V201.5H317V165.5H353V78Z"
        className={clsx(
          styles[apartments.V6.status],
          styles.apartment,
          apartmentNumber == apartments.V6.id && styles.active
        )}
        onClick={onApartmentClick(apartments.V6)}
      />
      <path
        d="M200.481 77.9028H226V88H260V77.9028H293.088V180.458H222V166.5H200.481V77.9028Z"
        className={clsx(
          styles[apartments.V4.status],
          styles.apartment,
          apartmentNumber == apartments.V4.id && styles.active
        )}
        onClick={onApartmentClick(apartments.V4)}
      />
      <path
        d="M97 257H168.5V236H202V288H167.864V315H109V285H97V257Z"
        className={clsx(
          styles[apartments.V1.status],
          styles.apartment,
          apartmentNumber == apartments.V1.id && styles.active
        )}
        onClick={onApartmentClick(apartments.V1)}
      />
      <path
        d="M97 802C97 831.823 121.177 856 151 856H200V732H97V802Z"
        className={clsx(
          styles[apartments.A5.status],
          styles.apartment,
          apartmentNumber == apartments.A5.id && styles.active
        )}
        onClick={onApartmentClick(apartments.A5)}
      />
      <path
        d="M108.947 705.027V732H204V696.375H167.5V675H97V705.027H108.947Z"
        className={clsx(
          styles[apartments.A6.status],
          styles.apartment,
          apartmentNumber == apartments.A6.id && styles.active
        )}
        onClick={onApartmentClick(apartments.A6)}
      />
      <path
        d="M200 856H225.627V845.859H259.771V856H293V753H221.611V767.019H200V856Z"
        className={clsx(
          styles[apartments.A4.status],
          styles.apartment,
          apartmentNumber == apartments.A4.id && styles.active
        )}
        onClick={onApartmentClick(apartments.A4)}
      />
      <path
        d="M97 675.734H168.5V697H202V644.342H167.864V617H109V647.38H97V675.734Z"
        className={clsx(
          styles[apartments.A7.status],
          styles.apartment,
          apartmentNumber == apartments.A7.id && styles.active
        )}
        onClick={onApartmentClick(apartments.A7)}
      />
      <rect
        x="420"
        y="77"
        width="60"
        height="192"
        className={clsx(
          styles[apartments.G1.status],
          styles.apartment,
          apartmentNumber == apartments.G1.id && styles.active
        )}
        onClick={onApartmentClick(apartments.G1)}
      />
      <rect
        x="653"
        y="77"
        width="63"
        height="192"
        className={clsx(
          styles[apartments.G5.status],
          styles.apartment,
          apartmentNumber == apartments.G5.id && styles.active
        )}
        onClick={onApartmentClick(apartments.G5)}
      />
      <path
        d="M653 181.5H624.5V203.5H596V270.5H624.5V259.5L653 259V181.5Z"
        className={clsx(
          styles[apartments.G4.status],
          styles.apartment,
          apartmentNumber == apartments.G4.id && styles.active
        )}
        onClick={onApartmentClick(apartments.G4)}
      />
      <path
        d="M512.5 90.5V77H568V161H480V90.5H512.5Z"
        className={clsx(
          styles[apartments.G2.status],
          styles.apartment,
          apartmentNumber == apartments.G2.id && styles.active
        )}
        onClick={onApartmentClick(apartments.G2)}
      />
      <path
        d="M621.608 90.5V77H568V161H653V90.5H621.608Z"
        className={clsx(
          styles[apartments.G3.status],
          styles.apartment,
          apartmentNumber == apartments.G3.id && styles.active
        )}
        onClick={onApartmentClick(apartments.G3)}
      />
      <rect
        x="96"
        y="323"
        width="200"
        height="60"
        className={clsx(
          styles[apartments.B5.status],
          styles.apartment,
          apartmentNumber == apartments.B5.id && styles.active
        )}
        onClick={onApartmentClick(apartments.B5)}
      />
      <path
        d="M96 468H184V383H108V412H96V468Z"
        className={clsx(
          styles[apartments.B3.status],
          styles.apartment,
          apartmentNumber == apartments.B3.id && styles.active
        )}
        onClick={onApartmentClick(apartments.B3)}
      />
      <rect
        width="200"
        height="58"
        transform="matrix(1 0 0 -1 96 609)"
        className={clsx(
          styles[apartments.B1.status],
          styles.apartment,
          apartmentNumber == apartments.B1.id && styles.active
        )}
        onClick={onApartmentClick(apartments.B1)}
      />
      <path
        d="M96 468H184V551H108V522.682H96V468Z"
        className={clsx(
          styles[apartments.B2.status],
          styles.apartment,
          apartmentNumber == apartments.B2.id && styles.active
        )}
        onClick={onApartmentClick(apartments.B2)}
      />
      <path
        d="M225 411.5V439L296 440V412H281.5V383H202V411.5H225Z"
        className={clsx(
          styles[apartments.B4.status],
          styles.apartment,
          apartmentNumber == apartments.B4.id && styles.active
        )}
        onClick={onApartmentClick(apartments.B4)}
      />
      <path
        d="M292 766H351V856H321.5V843H292V766Z"
        className={clsx(
          styles[apartments.A3.status],
          styles.apartment,
          apartmentNumber == apartments.A3.id && styles.active
        )}
        onClick={onApartmentClick(apartments.A3)}
      />
      <path
        d="M324 748H381V759H426V789H414.5V844.5H381V856H351V765.5H324V748Z"
        className={clsx(
          styles[apartments.A2.status],
          styles.apartment,
          apartmentNumber == apartments.A2.id && styles.active
        )}
        onClick={onApartmentClick(apartments.A2)}
      />
      <path
        d="M324 747.528H381V758.5H426V747.528V730H414.5V673.903H381V664H351V675.5H344V731H324V747.528Z"
        className={clsx(
          styles[apartments.A1.status],
          styles.apartment,
          apartmentNumber == apartments.A1.id && styles.active
        )}
        onClick={onApartmentClick(apartments.A1)}
      />
      <path
        d="M1342 132C1342 102.177 1317.82 78 1288 78H1239V201H1342V132Z"
        className={clsx(
          styles[apartments.E4.status],
          styles.apartment,
          apartmentNumber == apartments.E4.id && styles.active
        )}
        onClick={onApartmentClick(apartments.E4)}
      />
      <path
        d="M1146 88.6257H1115.5V78H1085V166H1146V88.6257Z"
        className={clsx(
          styles[apartments.E2.status],
          styles.apartment,
          apartmentNumber == apartments.E2.id && styles.active
        )}
        onClick={onApartmentClick(apartments.E2)}
      />
      <path
        d="M1330.05 227.5V201H1235V236H1271.5V257H1342V227.5H1330.05Z"
        className={clsx(
          styles[apartments.E5.status],
          styles.apartment,
          apartmentNumber == apartments.E5.id && styles.active
        )}
        onClick={onApartmentClick(apartments.E5)}
      />
      <path
        d="M1085.63 78H1056.33V89.47H1024V256.533H1056.84V269L1085.12 268.003V256.533H1093.21V201.178H1122V165.272H1085.63V78Z"
        className={clsx(
          styles[apartments.E1.status],
          styles.apartment,
          apartmentNumber == apartments.E1.id && styles.active
        )}
        onClick={onApartmentClick(apartments.E1)}
      />
      <path
        d="M1238 78H1212.65V88.0425H1178.87V78H1146V180H1216.62V166.117H1238V78Z"
        className={clsx(
          styles[apartments.E3.status],
          styles.apartment,
          apartmentNumber == apartments.E3.id && styles.active
        )}
        onClick={onApartmentClick(apartments.E3)}
      />
      <path
        d="M1342 257H1270.5V236H1237V288H1271.14V315H1330V285H1342V257Z"
        className={clsx(
          styles[apartments.E6.status],
          styles.apartment,
          apartmentNumber == apartments.E6.id && styles.active
        )}
        onClick={onApartmentClick(apartments.E6)}
      />
      <path
        d="M1342 802C1342 831.823 1317.82 856 1288 856H1239V732H1342V802Z"
        className={clsx(
          styles[apartments.I3.status],
          styles.apartment,
          apartmentNumber == apartments.I3.id && styles.active
        )}
        onClick={onApartmentClick(apartments.I3)}
      />
      <path
        d="M1330.05 705.027V732H1235V696.375H1271.5V675H1342V705.027H1330.05Z"
        className={clsx(
          styles[apartments.I2.status],
          styles.apartment,
          apartmentNumber == apartments.I2.id && styles.active
        )}
        onClick={onApartmentClick(apartments.I2)}
      />
      <path
        d="M1239 856H1213.37V845.859H1179.23V856H1146V753H1217.39V767.019H1239V856Z"
        className={clsx(
          styles[apartments.I4.status],
          styles.apartment,
          apartmentNumber == apartments.I4.id && styles.active
        )}
        onClick={onApartmentClick(apartments.I4)}
      />
      <path
        d="M1342 675.734H1270.5V697H1237V644.342H1271.14V617H1330V647.38H1342V675.734Z"
        className={clsx(
          styles[apartments.I1.status],
          styles.apartment,
          apartmentNumber == apartments.I1.id && styles.active
        )}
        onClick={onApartmentClick(apartments.I1)}
      />
      <rect
        width="59"
        height="192"
        transform="matrix(-1 0 0 1 1018 77)"
        className={clsx(
          styles[apartments.D5.status],
          styles.apartment,
          apartmentNumber == apartments.D5.id && styles.active
        )}
        onClick={onApartmentClick(apartments.D5)}
      />
      <rect
        width="63"
        height="192"
        transform="matrix(-1 0 0 1 785 77)"
        className={clsx(
          styles[apartments.D1.status],
          styles.apartment,
          apartmentNumber == apartments.D1.id && styles.active
        )}
        onClick={onApartmentClick(apartments.D1)}
      />
      <path
        d="M785 181H813.5V203H842V270H813.5V259L785 258.5V181Z"
        className={clsx(
          styles[apartments.D2.status],
          styles.apartment,
          apartmentNumber == apartments.D2.id && styles.active
        )}
        onClick={onApartmentClick(apartments.D2)}
      />
      <path
        d="M926.5 90.5V77H871V161H959V90.5H926.5Z"
        className={clsx(
          styles[apartments.D4.status],
          styles.apartment,
          apartmentNumber == apartments.D4.id && styles.active
        )}
        onClick={onApartmentClick(apartments.D4)}
      />
      <path
        d="M816.761 90.5V77H871V161H785V90.5H816.761Z"
        className={clsx(
          styles[apartments.D3.status],
          styles.apartment,
          apartmentNumber == apartments.D3.id && styles.active
        )}
        onClick={onApartmentClick(apartments.D3)}
      />
      <rect
        width="200"
        height="60"
        transform="matrix(-1 0 0 1 1343 323)"
        className={clsx(
          styles[apartments.J1.status],
          styles.apartment,
          apartmentNumber == apartments.J1.id && styles.active
        )}
        onClick={onApartmentClick(apartments.J1)}
      />
      <path
        d="M1343 468H1255V383H1331V412H1343V468Z"
        className={clsx(
          styles[apartments.J2.status],
          styles.apartment,
          apartmentNumber == apartments.J2.id && styles.active
        )}
        onClick={onApartmentClick(apartments.J2)}
      />
      <rect
        x="1343"
        y="609"
        width="200"
        height="58"
        transform="rotate(180 1343 609)"
        className={clsx(
          styles[apartments.J5.status],
          styles.apartment,
          apartmentNumber == apartments.J5.id && styles.active
        )}
        onClick={onApartmentClick(apartments.J5)}
      />
      <path
        d="M1343 468H1255V551H1331V522.682H1343V468Z"
        className={clsx(
          styles[apartments.J4.status],
          styles.apartment,
          apartmentNumber == apartments.J4.id && styles.active
        )}
        onClick={onApartmentClick(apartments.J4)}
      />
      <path
        d="M1214 411.5V439L1143 440V412H1157.5V383H1237V411.5H1214Z"
        className={clsx(
          styles[apartments.J3.status],
          styles.apartment,
          apartmentNumber == apartments.J3.id && styles.active
        )}
        onClick={onApartmentClick(apartments.J3)}
      />
      <path
        d="M1147 766H1087V856H1117V843H1147V766Z"
        className={clsx(
          styles[apartments.I5.status],
          styles.apartment,
          apartmentNumber == apartments.I5.id && styles.active
        )}
        onClick={onApartmentClick(apartments.I5)}
      />
      <path
        d="M1114 748H1057V759H1012V789H1023.5V844.5H1057V856H1087V765.5H1114V748Z"
        className={clsx(
          styles[apartments.I6.status],
          styles.apartment,
          apartmentNumber == apartments.I6.id && styles.active
        )}
        onClick={onApartmentClick(apartments.I6)}
      />
      <path
        d="M1114 747.086H1057V758H1012V747.086V729.651H1023.5V673.85H1057V664H1087V676H1095.5V730.646H1114V747.086Z"
        className={clsx(
          styles[apartments.I7.status],
          styles.apartment,
          apartmentNumber == apartments.I7.id && styles.active
        )}
        onClick={onApartmentClick(apartments.I7)}
      />

      <rect x="384" y="367" width="604" height="176" fill="white" />
      <defs>
        <pattern
          id="pattern0_34_4"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_34_4"
            transform="scale(0.000706714 0.00106838)"
          />
        </pattern>
        <image
          style={{ filter: "grayscale(100%)" }}
          id="image0_34_4"
          width="1415"
          height="936"
          xlinkHref={image}
        />
      </defs>
    </svg>
  );
}
