import { Modal, Spin } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ApartmentEditModel from "./ApartmentEditModel";
import { useQuery } from "react-query";
import jwtAxios from "../../auth/jwt-api";
import styles from "./index.module.scss";

export default function ApartmentInfoModel({ refetchFloor, refetchStats }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const floorNumber = searchParams.get("floorNumber");
  const apartmentNumber = searchParams.get("apartmentNumber");

  const { data, isLoading, refetch } = useQuery(
    ["apartment", apartmentNumber],
    () => jwtAxios.get(`/apartment/${apartmentNumber}`),
    {
      enabled: !!apartmentNumber,
    }
  );
  const apartment = data?.data?.data?.apartment || {};

  return (
    <>
      <Modal
        centered
        open={!!apartmentNumber}
        onCancel={() => {
          setSearchParams({ floorNumber });
        }}
        width={700}
        title="Apartment Informations"
        okText="Edit"
        onOk={() =>
          setSearchParams({ floorNumber, apartmentNumber, isEdit: 1 })
        }
      >
        {apartmentNumber && (
          <ApartmentEditModel
            refetchInfo={refetch}
            refetchFloor={refetchFloor}
            refetchStats={refetchStats}
            apartment={apartment}
            isLoading={isLoading}
          />
        )}
        {isLoading ? (
          <Spin
            spinning
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          ></Spin>
        ) : (
          <div className={styles.content}>
            <h1>
              Status: <span>{apartment.status}</span>
            </h1> <h1>
              Number: <span>{apartment.number}</span>
            </h1>
            <h1>
              Size: <span>{apartment.size}</span>
            </h1>
            <h1>
              Price: <span>{apartment.price}</span>
            </h1>
            <h1>
              Floor: <span>{apartment.floor}</span>
            </h1>
            <h1>
              Block: <span>{apartment.block}</span>
            </h1>
            <h1>
              Status: <span>{apartment.statusviable}</span>
            </h1>
            <h1>
              Client name: <span>{apartment.clientName}</span>
            </h1>
            <h1>
              Client phone: <span>{apartment.clientPhone}</span>
            </h1>{" "}
            <h1>
              Client payment: <span>{apartment.clientPayment}</span>
            </h1>
          </div>
        )}
      </Modal>
    </>
  );
}
