import {
  Button,
  Form,
  Input,
  notification,
  Modal,
  Select,
  Space,
  Spin,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import styles from "./index.module.scss";
import jwtAxios from "../../auth/jwt-api";

export default function ApartmentEditModel({
  refetchInfo,
  refetchFloor,
  refetchStats,
  apartment,
  isLoading,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const floorNumber = searchParams.get("floorNumber");
  const apartmentNumber = searchParams.get("apartmentNumber");
  const isEdit = searchParams.get("isEdit");
  const [form] = Form.useForm();

  const onFinish = async (data) => {
    setLoading(true);
    try {
      await jwtAxios.patch(`/apartment/${apartmentNumber}`, data);
      refetchInfo();
      refetchFloor();
      refetchStats();
      setSearchParams({
        floorNumber,
        apartmentNumber,
      });
      notification.success({
        message: "Success",
      });
    } catch (error) {
      notification.error({
        message: "Error",
      });
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    form.resetFields()
    form.setFields(
      Object.keys(apartment).map((key) => ({
        name: key,
        value: apartment[key],
      }))
    );
  }, [isEdit]);

  return (
    <Modal
      centered
      open={!!isEdit}
      onCancel={() => {
        setSearchParams({
          floorNumber,
          apartmentNumber,
        });
      }}
      width={1000}
      title="Edit Apartment"
      footer={""}
    >
      {isLoading ? (
        <Spin
          spinning
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        ></Spin>
      ) : (
        <Form
          onFinish={onFinish}
          layout="vertical"
          initialValues={apartment}
          form={form}
        >
          <div className={styles.form}>
            <Form.Item label="Number" name="number">
              <Input />
            </Form.Item>

            <Form.Item label="Price" name="price">
              <InputNumber style={{ display: "block", width: "100%" }} />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Select
                options={[
                  {
                    label: "Reserved",
                    value: "reserved",
                  },
                  {
                    label: "Sold",
                    value: "sold",
                  },
                  {
                    label: "Not Full Paid",
                    value: "not_full_paid",
                  },
                  {
                    label: "Aviable",
                    value: "aviable",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label="Size" name="size">
              <Input />
            </Form.Item>

            <Form.Item label="Client name" name="clientName">
              <Input />
            </Form.Item>
            <Form.Item label="Client phone" name="clientPhone">
              <Input />
            </Form.Item>
            <div></div>
            <div></div>
            <Form.Item label="Client payment" name="clientPayment">
              <InputNumber style={{ display: "block", width: "100%" }} />
            </Form.Item>
          </div>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}
