//This is a login page

import React from "react";
import logo from "../../assets/logo.png";
import styles from "./index.module.scss";
 import { Button, Checkbox, Form, Input } from "antd";
import { useAuthAction } from "../../auth/JWTAuthProvider";

export default function Login() {
  const { signInUser } = useAuthAction(); 
  
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img src={logo} alt="" />

        <Form
          onFinish={signInUser}
          initialValues={{
            rememberMe: true,
            username: "root",
            password: "root",
          }}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Username is required",
              },
            ]}
          >
            <Input placeholder={"Username:"} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(new Error("Password is required"));
                  }
                     return Promise.resolve();
                 
                   
                  // return Promise.reject(
                  //   new Error("Password length must be at least 8 characters")
                  // );
                },
              }),
            ]}
          >
            <Input.Password placeholder={"Password:"} />
          </Form.Item>
          <Form.Item name="rememberMe" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
