//This is uni auth system that contains user informations and actions such as login logout etc.

import { createContext, useContext, useEffect, useState } from "react";
import jwtAxios, { setAuthToken } from "./jwt-api";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

const JWTAuthContext = createContext({});

const JWTAuthActionsContext = createContext({});

export const useAuthData = () => useContext(JWTAuthContext);
export const useAuthAction = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setUserData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }

      setUserData({
        user: null,
        isLoading: false,
        isAuthenticated: true,
      });
      // jwtAxios
      //   .get("/houses/")
      //   .then(() => {
      //     setUserData({
      //       user: null,
      //       isLoading: false,
      //       isAuthenticated: true,
      //     });
      //   })
      //   .catch(() =>{
      //     console.log(111);
      //     setUserData({
      //       user: null,
      //       isLoading: false,
      //       isAuthenticated: false,
      //     })}
      //   );

      setAuthToken(token);
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ username, password, rememberMe }) => {
    setUserData({ user: null, isAuthenticated: true, isLoading: false });
    try {
      setUserData((prev) => ({ ...prev, isLoading: true }));

      const res = await jwtAxios.post("/auth/login/", {
        username,
        password,
      });
      
      setAuthToken(res.data.data.token);

      setUserData({ user: null, isAuthenticated: true, isLoading: false });

      rememberMe || localStorage.removeItem("token");
      navigate("/floor");
    } catch (error) {
      notification.error({
        message:
          error.response?.data?.error || "Username ot password is incorrect",
      });
      setUserData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const logout = async () => {
    setAuthToken();
    setUserData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    // api.success({
    //   message: t("notification.success.logout"),
    // });
  };

  //   const refetchProfile = async () => {
  //     try {
  //       const res = await jwtAxios.get("/web/profile", {
  //         params: { lang: localStorage.getItem("language") },
  //       });
  //       setUserData({ user: res.data, isAuthenticated: true, isLoading: false });
  //       return res;
  //     } catch (error) {
  //      }
  //   };

  return (
    <>
      <JWTAuthContext.Provider
        value={{
          ...userData,
        }}
      >
        <JWTAuthActionsContext.Provider
          value={{
            signInUser,
            logout,
            setUserData,
          }}
        >
          {children}
        </JWTAuthActionsContext.Provider>
      </JWTAuthContext.Provider>
    </>
  );
};
export default JWTAuthAuthProvider;
