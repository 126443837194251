// Layout for webapp

import { Route, Routes, Navigate } from "react-router-dom";
import Floor from "./Floor";
import Floors from "./Floors";
import Login from "./Login";
import Navbar from "../components/Navbar";
import { useAuthData } from "../auth/JWTAuthProvider";
import { Spin } from "antd";

/// Routes for anonymus users
const authRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to="/login" />,
  },
];

/// Routes for authorized users
const mainRoutes = [
  {
    path: "/floors",
    element: <Floors />,
  },
  {
    path: "/floor",
    element: <Floor />,
  },
  {
    path: "*",
    element: <Navigate to="/floors" />,
  },
];

export default function AppLayout() {
  const { isAuthenticated, isLoading } = useAuthData();
  if (isLoading) {
    return (
      <Spin
        spinning
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></Spin>
    );
  }
  return (
    <div>
      {isAuthenticated && <Navbar></Navbar>}
      <Routes>
        {(isAuthenticated ? mainRoutes : authRoutes).map((route) => (
          <Route key={route.path} {...route}></Route>
        ))}
      </Routes>
    </div>
  );
}
