// This page for selecting floor and getting its infos.
// Image, every interactive floors, floor numbers all created with svg tags

import React from "react";
import SVG from "./SVG";
import styles from "./index.module.scss";
import { Button, Spin } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import jwtAxios from "../../auth/jwt-api";
import { useQuery } from "react-query";

export default function Floors() {
  // const [selectedFloor, setSelectedFloor] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFloor = searchParams.get("selectedFloor");

  const { data, isLoading } = useQuery(["floor", selectedFloor], () =>
    jwtAxios.get(`apartment/floor-stats/${selectedFloor}`),{
      enabled: !!selectedFloor
    }
  );

  return (
    <div className={styles.container}>
      <div
        className={styles["svg-container"]}
        style={{
          margin: selectedFloor ? "0" : "0 auto",
        }}
      >
        <SVG selectedFloor={selectedFloor} setSearchParams={setSearchParams} />
      </div>{" "}
      {selectedFloor && (
        <div className={styles["floor-info"]}>
          <h1>Floor {selectedFloor}</h1>
          {isLoading ? (
            <Spin
              spinning
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            ></Spin>
          ) : (
            <>
              {data?.data?.data?.results?.map((data) => (
                <h2 className="montaga">
                  {data.status}: {data.total}
                </h2>
              ))}
            </>
          )}
          <Link to={`/floor?floorNumber=${selectedFloor}`}>
            <Button block type="primary">
              Show more
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
