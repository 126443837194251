// Platform uses module scss in order to not interupt other peoples code use module based styling
// If you want to add global styles you can write it inside styles file and import it to the index.scss

import { BrowserRouter } from "react-router-dom";
import AppLayout from "./pages";
import JWTAuthAuthProvider from "./auth/JWTAuthProvider";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#049C56",
            },
          }}
        >
          <JWTAuthAuthProvider>
            <AppLayout />
          </JWTAuthAuthProvider>
        </ConfigProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
