import React from "react";
import styles from "./index.module.scss";
import { useAuthAction } from "../../auth/JWTAuthProvider";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.png";

export default function Navbar() {
  const { logout } = useAuthAction();
  return (
    <nav
      style={{
        height: "70px",
      }}
      className={styles.navbar}
    >
      <img src={logo} alt="" />
      <NavLink
        to="/floors"
        className={({ isActive }) => isActive && styles.active}
      >
        Building
      </NavLink>
      <NavLink to="" onClick={logout} style={{marginLeft:"auto"}}>
        Logout
      </NavLink>
    </nav>
  );
}
